.container--profile {
  margin-top: 4rem;
  margin-bottom: 8rem;
}

.list--columned {
  padding-right: 0vw;
  padding-left: 1rem;
  -webkit-column-gap: 2rem;
  column-gap: 2rem;
  -webkit-column-count: 3;
  column-count: 3;
}

@media screen and (max-width: 991px) {
  .list--columned {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (max-width: 991px) {
  .container--profile {
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 767px) {
  .list--columned {
    -webkit-column-count: 1;
    column-count: 1;
  }
}

@media screen and (max-width: 479px) {
  .list--columned {
    padding-left: 0.75rem;
  }
}

.profile__headshot-wrapper {
  position: absolute;
  top: 3rem;
  right: 3rem;
  bottom: 3rem;
  left: 3rem;
  overflow: hidden;
  border-radius: 50%;
}

@media screen and (max-width: 991px) {
  .profile__headshot-wrapper {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .profile__headshot-wrapper {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
}

@media screen and (max-width: 479px) {
  .profile__headshot-wrapper {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
}

.shape-outside.profile__headshot {
  position: relative;
  width: 38.197vw;
  height: 38.197vw;
  margin-top: 5rem;
  margin-left: -1.5%;
  border-radius: 50%;
}

@media screen and (max-width: 991px) {
  .shape-outside.profile__headshot {
    width: 50vw;
    height: 50vw;
    margin-top: 4rem;
    margin-left: -10%;
  }
}

@media screen and (max-width: 767px) {
  .shape-outside.profile__headshot {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 479px) {
  .shape-outside.profile__headshot {
    margin-left: -10%;
  }
}

.profile__headshot-img {
  display: block;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile__name {
  margin-top: 0rem;
  margin-bottom: 0em;
}

.profile__byline {
  margin-top: 0em;
  font-size: 1.5rem;
  font-weight: 400;
}

@media screen and (max-width: 479px) {
  .profile__byline {
    font-size: 1.25rem;
  }
}

.profile__byline.h4 {
  margin-bottom: 1em;
}

.profile__name-pronouns {
  display: block;
  margin-bottom: -0.7rem;
  opacity: 0.7;
  font-family: Inter, sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0.07em;
  line-height: 1;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .profile__name-pronouns {
    margin-top: 0.25rem;
    margin-bottom: -0.95rem;
  }
}

.profile__page-title {
  padding: 12rem 10% 4rem;
  background-color: #231f20;
  color: #ededea;
}

@media screen and (max-width: 991px) {
  .profile__page-title {
    padding-top: 10rem;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .profile__page-title {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 479px) {
  .profile__page-title {
    padding-top: 6rem;
  }
}

.profile__byline-item {
  font-weight: 800;
}

.profile__services {
  padding-left: 0;
}

.profile__services li::before {
  display: none;
}

.list--columned.profile__services {
  font-size: 1.1rem;
  letter-spacing: 0em;
}
