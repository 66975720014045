.halves.halves--services {
  -webkit-align-items: center;
  align-items: center;
  padding-right: 8%;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .halves.halves--services {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding-top: 4rem;
    padding-right: 6%;
    padding-bottom: 4rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
  }
}

@media screen and (max-width: 479px) {
  .halves.halves--services {
    padding-right: 7.5%;
  }
}

.halves__title {
  margin-top: 0em;
  margin-left: 0em;
}

@media screen and (max-width: 991px) {
  .halves__title {
    margin-top: 0em;
  }
}

.service-icon__wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.service-icon__wrapper .service-icon {
  width: 100%;
  max-width: 45vh;
}

@media screen and (max-width: 991px) {
  .service-icon__wrapper {
    width: 6rem;
    height: 6rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .service-icon__wrapper {
    width: 4rem;
    height: 4rem;
  }
}

.service-icon {
  display: block;
  width: 45vh;
  max-width: 231px;
  margin: 0 auto;
}

/* Establish styles for all SVG elements. */
.service-icon circle,
.service-icon line,
.service-icon path,
.service-icon polygon,
.service-icon rect {
  /* Dark Grey */
  stroke-width: 2px;
  stroke: #72686b;
  fill: none;
}

@media (max-width: 991px) {
  .service-icon circle,
  .service-icon line,
  .service-icon path,
  .service-icon polygon,
  .service-icon rect {
    stroke-width: 10px !important;
  }
}

/* Setup masks for certain service icons. */
.service-icon--diagnostics #mask {
  stroke: none;
  fill: #f3f2ef;
}

.service-icon--design #walls {
  stroke: none;
  fill: #f3f2ef;
}

/*
 * Since you cannot control the stroke location (inner vs outter) in SVGs and all strokes
 * are increased to 10px starting at 991px viewport, we need to hack this by doubling the stroke
 * since the #walls/#mask covers half of the stroke.
 **/
@media (max-width: 991px) {
  .service-icon--design #doorway,
  .service-icon--diagnostics #rim {
    stroke-width: 20px !important;
  }
}
