.layout-sidebar-first {
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 84%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

.width--gr-wide {
  width: 61.803%;
}

.width--gr-narrow {
  width: 38.197%;
}

.form--newsletter-signup {
  padding-top: 1rem;
  border-top-style: none;
}

@media screen and (max-width: 767px) {
  .form--newsletter-signup {
    padding-top: 0rem;
  }
}

.button.button--newsletter-signup {
  margin-top: 1em;
  margin-bottom: 0em;
}

.newsletter-signup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

@media screen and (max-width: 991px) {
  .newsletter-signup {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  }
}

.newsletter-signup__heading {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .newsletter-signup__heading {
    width: 80%;
    margin-top: 0rem;
    margin-right: 0%;
  }
}

@media screen and (max-width: 479px) {
  .talk-bubble.newsletter-signup__heading {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .talk-bubble.newsletter-signup__heading {
    width: 42.187%;
    margin-top: 3rem;
    margin-right: -4%;
  }
}

.newsletter-signup__heading-title {
  margin-top: 0em;
  margin-bottom: 1rem;
  margin-left: 0em;
  font-size: 1.5rem;
}

.newsletter-signup__heading-paragraph {
  margin-bottom: 0em;
}

.talk-bubble {
  position: relative;
  padding: 3rem 4rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 6rem;
  border-color: #b2b2ab;
  background-color: #f3f2ef;
}

@media screen and (max-width: 767px) {
  .talk-bubble {
    padding: 2rem 3rem;
    border-radius: 4rem;
  }
}

@media screen and (max-width: 479px) {
  .talk-bubble {
    padding-right: 2rem;
    padding-left: 2rem;
    border-radius: 3rem;
  }
}

.talk-bubble__pseudoelement--before {
  position: absolute;
  z-index: 1;
  top: 100%;
  border-top: 2.5em solid #f3f2ef;
  border-right: 3em solid transparent;
}

.talk-bubble__pseudoelement--before {
  left: calc(6rem + 1px);
  border-top: calc(2.5em - 2px) solid #f3f2ef;
  border-right: calc(3em - 3px) solid transparent;
}

@media (max-width: 991px) {
  /* Layer over neighboring pseudo-element `--after` in such a way that a 1px stroke is created. */
  .talk-bubble__pseudoelement--before {
    left: calc(6rem + 1px);
    border-top: calc(2.5em - 2px) solid #f3f2ef;

    /* White */
    border-right: calc(3em - 3px) solid transparent;
  }
}

@media (max-width: 568px) {
  .talk-bubble__pseudoelement--before {
    left: calc(4rem + 1px);
  }
}

.talk-bubble__pseudoelement--after {
  position: absolute;
  top: 100%;
  left: 6rem;
  border-top: 2.5em solid #b2b2ab;
  border-right: 3em solid transparent;
}

@media screen and (max-width: 568px) {
  .talk-bubble__pseudoelement--after {
    left: 4rem;
  }
}

/* Talk Bubble Dark */

.talk-bubble--dark {
  background-color: #231f20;
}

.talk-bubble--dark.talk-bubble--contact-cta {
  position: absolute;
  z-index: -1;
  top: 0%;
  width: 12rem;
}

.talk-bubble--dark .talk-bubble__pseudoelement--before {
  border-top-color: #231f20;
}

/* Talk Bubble Reverse */

@media screen and (max-width: 991px) {
  .talk-bubble--reverse.width--gr-wide.newsletter-signup__form {
    width: 75%;
    margin-top: -2rem;
    margin-left: 25%;
  }
}

@media screen and (max-width: 767px) {
  .talk-bubble--reverse.width--gr-wide.newsletter-signup__form {
    width: 80%;
    margin-top: 1rem;
    margin-left: 20%;
  }
}

@media screen and (max-width: 479px) {
  .talk-bubble--reverse.width--gr-wide.newsletter-signup__form {
    width: 100%;
    margin-top: 3rem;
    margin-left: 0%;
  }
}

.talk-bubble--reverse.newsletter-signup__form {
  display: block;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 61.803%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.talk-bubble--reverse .talk-bubble__pseudoelement--before,
.talk-bubble--reverse .talk-bubble__pseudoelement--after {
  left: auto;
  border-right: none;
}

.talk-bubble--reverse .talk-bubble__pseudoelement--before {
  right: calc(6rem + 1px);
  border-left: calc(3em - 2px) solid transparent;
}

@media (max-width: 568px) {
  .talk-bubble--reverse .talk-bubble__pseudoelement--before {
    right: calc(4rem + 1px);
  }
}

.talk-bubble--reverse .talk-bubble__pseudoelement--after {
  right: 6rem;
  border-left: 3em solid transparent;
}

@media (max-width: 568px) {
  .talk-bubble--reverse .talk-bubble__pseudoelement--after {
    right: 4rem;
  }
}

/* Hubspot form style overrides. */

/* @ToDo – Remove once we better solve for form embeds from HS. */

/*
// Newsletter form HubSpot class: .hs-form-adf818ed-67f9-4582-b338-a00560f83ace
// Contact Us form HubSpot class: .hs-form-c37d3ce1-287d-4056-8e89-c446aef5be8c
*/

/* First some duplication of Webflow base form styles. */
.hs-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  margin-bottom: 10px;
  padding: 8px 12px;
  color: #333;
  font-size: 1em;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
  appearance: none;
}

.hs-input:-moz-placeholder,
.w-select:-moz-placeholder,
.hs-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder,
.hs-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
}

.hs-input::-moz-placeholder,
.w-select::-moz-placeholder {
  opacity: 1;
  color: #999;
}

.hs-input:focus,
.w-select:focus {
  border-color: #3898ec;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  outline: 0;
}

.hs-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  background-color: #eee;
  cursor: not-allowed;
}

/* This code very closely tracks styles in webflow.css. */
.hs-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 2rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-flex-line-pack: start;
}

/* The newsletter HubSpot form needs to align items to flex-end; */
.hs-form.hs-form-adf818ed-67f9-4582-b338-a00560f83ace {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.hs-form label {
  color: hsla(0, 0%, 100%, 0.5);
}

/* Custom styles for the contact form on the homepage */

/* Edit the form here: https://app.hubspot.com/forms/21228873/editor/c37d3ce1-287d-4056-8e89-c446aef5be8c/edit/form  */
.hs-form-c37d3ce1-287d-4056-8e89-c446aef5be8c .hs-input {
  color: #d3d3cc;
}

/* Light variant class used to override styles for the */
.form-variant-light .hs-form .hs-input {
  color: #333;
}

.form-variant-light .hs-form label {
  color: #72686b;
}

.hs-form-field {
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  min-width: 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-flex-item-align: end;
}

.hs-form.stacked .hs-form-field {
  min-width: 100%;
}

.hs-form.stacked .hs-form-field.hs-firstname,
.hs-form.stacked .hs-form-field.hs-lastname {
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  min-width: 47.5%;
  -webkit-box-flex: 0;
}

/* Ensure the first name and last names take up full width on mobile. */
@media screen and (max-width: 767px) {
  .hs-form.stacked .hs-form-field.hs-firstname,
  .hs-form.stacked .hs-form-field.hs-lastname {
    width: 100%;
  }
}

/* These .hs-form .input styles closely track .input__input styles from webflow.css */
.hs-input {
  position: relative;
  width: 100%;
  max-width: 800px;
  min-height: 3rem;
  margin-bottom: 0px;
  padding: 1rem 0px;
  -webkit-transition: all 280ms ease-in-out;
  transition: all 280ms ease-in-out;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-radius: 0px;
  border-color: #000 #000 #72686b;
  background-color: transparent;
  color: #d3d3cc;
  font-size: 1rem;
  line-height: 1;
  cursor: text;
}

.hs-input:focus {
  border-bottom-style: dashed;
  border-bottom-color: #396eea;
}

.hs-input::-webkit-input-placeholder,
.hs-input:-ms-input-placeholder,
.hs-input::-ms-input-placeholder,
.hs-input::placeholder {
  color: #72686b;
}

.hs-input.input__input--focus {
  box-shadow: inset 0 0 0 1px #231f20;
  color: #231f20;
}

.hs-input.inout__input--warning {
  box-shadow: inset 0 0 0 1px #ef243b;
  color: #8e002e;
}

.hs-input.input__select {
  padding-right: 3em;
  cursor: pointer;
}

.hs-input.input__input--white-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
  color: #d3d3cc;
}

.hs-input.input__input--white-text:focus {
  border-bottom-color: #396eea;
}

.hs-input.input__input--white-text::-webkit-input-placeholder,
.hs-input.input__input--white-text:-ms-input-placeholder,
.hs-input.input__input--white-text::-ms-input-placeholder,
.hs-input.input__input--white-text::placeholder {
  color: rgba(178, 178, 171, 0.75);
}

.hs-message .hs-input {
  width: 100%;
  min-height: 7.5em;
  margin-bottom: 0px;
  padding: 1rem 0px;
  border-bottom: 1px solid #72686b;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.3;
}

/**
 * Styles the required field indicator to use our icon and place it to the left
 * of the label.
 */
.hs-form-required {
  position: absolute;
  right: calc(100% + 0.25em);
  color: transparent;
}

/**
 * Negate the positioning rules for labels accompanying checkboxes.
 */
.hs-fieldtype-booleancheckbox .hs-form-required {
  position: static;
  margin-left: -6px;
}

.hs-form-required::after {
  content: "";
  color: #b2b2ab;
  font-family: Icons;
  font-weight: 400;
}

/* Ensures HubSpot error messages don't get the special "required" asterisk icon. */
.hs-error-msg::before,
.hs-fieldtype-booleancheckbox .inputs-list li::before {
  display: none;
}

/* Styling Hubspot boolean checkboxes. */
.hs-fieldtype-booleancheckbox .inputs-list {
  margin: 0;
  padding: 0;
}

label.hs-form-booleancheckbox-display {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0px;
  float: none;
  border-bottom: 1px solid #72686b;
  color: #231f20;
  font-size: 1em;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.hs-input[type="checkbox"] {
  position: relative;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 1.5rem;
  max-width: auto;
  height: 1.5rem;
  min-height: auto;
  margin-top: 0px;
  margin-right: 0.75em;
  margin-left: 0px;
  -webkit-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-radius: 0em;
  border-color: #b2b2ab;
  font-family: Icons, sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-flex: 0;
}

.hs-input[type="checkbox"]::before {
  content: '';
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 320ms cubic-bezier(0.22, 1, 0.36, 1);
  opacity: 0;
  color: #f3f2ef;
  font-family: Icons;
}

.hs-input[type="checkbox"]:checked {
  border-radius: 50%;
  border-color: #72686b;
  background-color: #72686b;
}

.hs-input[type="checkbox"]:checked::before {
  transform: scale(1);
  opacity: 1;
}

/* Styling Hubspot form actions to match Webflow styles. */
.hs-submit .actions {
  padding-top: 1rem;
}

/* Remove the action padding on the newsletter signup form. */
.hs-form-adf818ed-67f9-4582-b338-a00560f83ace_98318b53-84e5-4a74-bc4d-ff4a518a5008 .hs-submit .actions {
  padding-top: 0;
}

.hs-button {
  display: inline-block;
  position: relative;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 1em 2em;
  overflow: hidden;
  border: none;
  border-radius: 2em;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #231f20;
  box-shadow: inset 0 0 0 1px #ededea;
  color: #ededea;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0em;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-box-flex: 0;
}

.form-variant-light .hs-button {
  box-shadow: inset 0 0 0 1px #231f20;
  color: #231f20;
}

/* Since we don't get a button element from HS, the nifty hover we have in Webflow isn't possible.
  ::after is not available on submit elements. This is as yet unsolved for.
 */
.hs-button:hover {
  background-color: #ededea;
  color: #231f20;
}

.form-variant-light .hs-button:hover {
  background-color: #231f20;
  color: #ededea;
}

.hs-error-msgs {
  margin: 0;
  padding: 0;
}

.hs-error-msgs li::before {
  display: none;
}

/* Hide the gross error roll up that HubSpot provides. */
.hs_error_rollup {
  display: none;
}

.hbspt-form .submitted-message {
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  margin-left: -0.03em;
  font-family: Inter, sans-serif;
  font-size: 2.25rem;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.1;
  text-align: center;
}

.hbspt-form .submitted-message::before {
  content: "";
  display: inline-block;
  padding: 1px;
  -webkit-background-clip: text;
  background-color: #fff;
  background-image: linear-gradient(450deg, #ef243b, rgba(96, 97, 151, 0) 70%), linear-gradient(135deg, #396eea 30%, rgba(57, 110, 234, 0) 80%), linear-gradient(225deg, #c2c700 5%, rgba(194, 199, 0, 0) 80%), linear-gradient(0deg, #fc00a1, rgba(252, 0, 161, 0) 71%);
  color: #f3f2ef;
  font-family: Icons;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1;
  -webkit-text-fill-color: transparent;
}
