.case-study__screen {
  width: 38.197vw;
  max-width: 2000px;
  height: 30vw;
  max-height: 1125px;
  margin-right: auto;
  margin-bottom: 3rem;
  margin-left: auto;
  overflow: hidden;
  border-radius: 1rem;
  background-color: #d3d3cc;
}

@media screen and (min-width: 2000px) {
  .case-study__screen {
    width: auto;
  }
}

@media screen and (max-width: 991px) {
  .case-study__screen {
    width: 61.803vw;
    height: 35vw;
    border-radius: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .case-study__screen {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
  }
}

@media screen and (max-width: 479px) {
  .case-study__screen {
    width: 85vw;
    height: 48vw;
  }
}

.case-study__screen-image {
  display: block;
  position: relative;
  width: 100%;
}

/* Animated Scroll for Case Study Image */
@keyframes autoScroll {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-89%);
  }
}
