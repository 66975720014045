.case-study-teaser__title {
  margin-bottom: 1rem;
  color: #72686b;
}

@media screen and (max-width: 767px) {
  .case-study-teaser__title {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
}

.case-study-teaser__testimonial {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.3;
  text-indent: -0.6ch;
  -webkit-box-flex: 1;
}

@media screen and (max-width: 991px) {
  .case-study-teaser__testimonial {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 767px) {
  .case-study-teaser__testimonial {
    font-size: 1.1rem;
    text-align: left;
    text-indent: -0.6ch;
  }
}

@media screen and (max-width: 479px) {
  .case-study-teaser__testimonial {
    font-size: 1rem;
  }
}

.case-study-teaser__testimonial--unindented {
  text-indent: 0;
}

.case-study-teaser__summary {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0rem;
  -webkit-box-flex: 1;
}

.button__wrapper--case-study-teaser {
  width: 100%;
  margin-top: 3rem;
}

@media screen and (max-width: 767px) {
  .button__wrapper--case-study-teaser {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-top: 1.5rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
  }
}
