.page-title {
  position: relative;
  padding-top: 25vh;
  padding-bottom: 20vh;
  background-color: #231f20;
  color: #ededea;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .page-title {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }
}

@media screen and (max-width: 767px) {
  .page-title {
    padding-top: 6rem;
    padding-bottom: 5rem;
    text-align: left;
  }
}

@media screen and (max-width: 479px) {
  .page-title {
    padding-bottom: 6rem;
  }
}

.ux__anchor-menu-item {
  display: inline-flex;
}

.ux__anchor-menu {
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0%;
  right: 0%;
  bottom: auto;
  left: 0%;
  justify-content: center;
  height: 2.5rem;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  font-size: 0.8rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
}

.ux__anchor-menu-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 2.5rem;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 1rem;
  padding-left: 1rem;
  overflow: hidden;
  color: #b2b2ab;
  text-align: center;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.ux__anchor-menu-item:hover {
  color: #c2c700;
}

.ux__block {
  position: relative;
  padding-top: 12rem;
  padding-bottom: 12rem;
}

@media screen and (max-width: 991px) {
  .ux__block {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media screen and (max-width: 767px) {
  .ux__block {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.ux__block--compact {
  height: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media screen and (max-width: 991px) {
  .ux__block--compact {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.ux__heading {
  margin-bottom: 2rem;
  margin-left: 0em;
  padding-top: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #231f20;
}

.container--text {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.ux__grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0.5rem;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-row-gap: 0.5rem;
  -ms-grid-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  -webkit-align-content: start;
  align-content: start;
  -webkit-justify-content: stretch;
  justify-content: stretch;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  -ms-flex-line-pack: start;
}

@media screen and (max-width: 767px) {
  .ux__grid {
    display: block;
    grid-auto-columns: 1fr;
    grid-auto-flow: column dense;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.ux__grid-icons {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 4rem;
  font-family: Icons, sans-serif;
  font-size: 6vw;
  text-align: center;
  justify-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.ux__icon--large {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .ux__grid-icons {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .ux__grid-icons {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row dense;
    grid-column-gap: 2rem;
    -ms-grid-columns: 1fr;
    grid-row-gap: 2rem;
    -ms-grid-rows: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.ux__grid-caption {
  padding: 0.5em;
  background-color: rgba(35, 31, 32, 0.9);
  color: #ededea;
  font-size: 0.8rem;
}

.ux__color {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 3rem 2rem;
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 0.03em;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.ux__color.ux__color--relative {
  position: relative;
  padding: 0rem;
}

.ux__color-text--absolute {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #f3f2ef;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.icon.ux__icon {
  margin-bottom: 2rem;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .button__wrapper.padding {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a7e9-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a7f0-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a7f7-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a80c-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a813-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a805-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a7fe-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a821-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a82f-df6e58c7,
#w-node-_78dd628b-a1e9-b2f1-40e6-215d04d9a81a-df6e58c7,
#w-node-e4f8849b-32c1-68be-657c-5e3c12cb09b4-df6e58c7,
#w-node-bf35991f-36bd-8ffe-724a-7588b8aa8bb1-df6e58c7,
#w-node-_048aa132-90c4-48c3-f098-6541d0c7869e-df6e58c7,
#w-node-df3069d9-9221-c080-e6ab-56322e80575c-df6e58c7,
#w-node-_02710d35-cf53-d316-b92a-194425952901-df6e58c7,
#w-node-b32f2794-5d3a-0ff1-b27c-6049e21f7f16-df6e58c7,
#w-node-e6cfc866-9bcd-330d-f5cf-84e6e01d8286-df6e58c7,
#w-node-a805f52a-f6aa-bf74-e560-9d03a5490bff-df6e58c7,
#w-node-_0b23a1fd-03ba-f90e-29d8-62d0ce1b4eb4-df6e58c7,
#w-node-_9fe73d65-b6ff-c9ad-be53-78d019399fb3-df6e58c7,
#w-node-_546e9707-845e-080b-7f82-c0df8e4be664-df6e58c7,
#w-node-_6f41d085-98ee-9c8e-9334-3f04fce06486-df6e58c7,
#w-node-_6f41d085-98ee-9c8e-9334-3f04fce06489-df6e58c7 {
  -ms-grid-column: span 1;
  grid-column-end: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 3;
  grid-row-end: span 3;
  grid-row-start: span 3;
  align-self: stretch;
  -ms-grid-row-align: stretch;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 3;
}

#w-node-_6f41d085-98ee-9c8e-9334-3f04fce06483-df6e58c7,
#w-node-a97c2d4f-aaf1-73b4-8f01-38ab4aeaa8b2-df6e58c7,
#w-node-d96aba4c-c681-b2c4-13a9-9a782e7ca7ca-df6e58c7 {
  -ms-grid-column: span 1;
  grid-column-end: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 6;
  grid-row-end: span 6;
  grid-row-start: span 6;
  align-self: stretch;
  -ms-grid-row-align: stretch;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 6;
}

#w-node-_64ec70e4-8361-d9e9-6592-2aa17a3c97ff-2dd17d13,
#w-node-a97c2d4f-aaf1-73b4-8f01-38ab4aeaa8b5-df6e58c7,
#w-node-_64ec70e4-8361-d9e9-6592-2aa17a3c97ff-df6e58c7,
#w-node-d96aba4c-c681-b2c4-13a9-9a782e7ca7cd-df6e58c7 {
  -ms-grid-column: span 3;
  grid-column-end: span 3;
  grid-column-start: span 3;
  -ms-grid-row: span 1;
  grid-row-end: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 3;
  -ms-grid-row-span: 1;
}

#w-node-_71100bd6-516a-e306-f935-6850d760e4ad-cb171e8c,
#w-node-e35acb48-30f6-978c-e21b-6a001d19cff3-cb171e8c,
#w-node-_59f830a1-0d0a-be42-20cd-dee3da8b16bf-cb171e8c,
#w-node-_6fd9d7f0-bcc3-2325-7de5-9a7525d9d9a4-cb171e8c {
  -ms-grid-column: span 1;
  grid-column-end: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 1;
  grid-row-end: span 1;
  grid-row-start: span 1;
  align-self: center;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8a2ff7e5-1659-6508-0049-3000deb66fec-cb171e8c {
  -ms-grid-column: 1;
  grid-column-end: 2;
  grid-column-start: 1;
  -ms-grid-row: 3;
  grid-row-end: 3;
  grid-row-start: 3;
  align-self: center;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 0;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a713bbe0-9e1f-3740-c83c-ca86ab059f44-cb171e8c {
  -ms-grid-column: 3;
  grid-column-end: 4;
  grid-column-start: 3;
  -ms-grid-row: 2;
  grid-row-end: 3;
  grid-row-start: 2;
  align-self: center;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-bc73de55-ed56-1574-90ff-318e6b3cf2e9-cb171e8c {
  -ms-grid-column: 2;
  grid-column-end: 3;
  grid-column-start: 2;
  -ms-grid-row: 3;
  grid-row-end: 4;
  grid-row-start: 3;
  align-self: center;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6c446648-0c8e-f3f7-6679-058e0241b24f-cb171e8c {
  -ms-grid-column: 3;
  grid-column-end: 4;
  grid-column-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  grid-row-start: 3;
  align-self: center;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-beabe5ca-1fb1-45eb-05fe-f49dd1e3bcbd-29f0b517,
#w-node-ef3989f4-e5b1-b793-e228-52ab2ca18d59-29f0b517,
#w-node-_12fc44ce-d208-8302-3daf-c65b41a31f35-29f0b517,
#w-node-_60f38c89-648b-e68f-582b-a348432fdc69-29f0b517,
#w-node-bcb5d6e5-6a69-7b02-9386-5a28769d0036-29f0b517,
#w-node-_89fd43e9-f5de-9718-4cec-4562127dab25-29f0b517,
#w-node-ca8f8d9c-5753-0d05-6561-defd2e23ae41-29f0b517 {
  -ms-grid-column: span 1;
  grid-column-end: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 1;
  grid-row-end: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 1;
}

#w-node-_381980ae-bcdb-a9c7-a577-620d98e85da1-2dd17d13,
#w-node-abeedff2-5608-4592-8bcf-1327026a9c35-2dd17d13,
#w-node-c2765865-c3e4-dd1c-6ab7-1555aa121aa9-2dd17d13,
#w-node-d633ffa7-401a-c155-bc9b-be725aa911ff-2dd17d13,
#w-node-e1cb61db-62ef-52bf-f0c6-d5ff9073512c-2dd17d13,
#w-node-_114ca478-97f3-907c-78cf-20ace8bd79c4-2dd17d13,
#w-node-_87d0d355-91d0-e6b3-43ef-917fc9686017-2dd17d13,
#w-node-_7b63b936-09cb-4983-8d9f-5dc976d43f0e-2dd17d13 {
  -ms-grid-column: span 1;
  grid-column-end: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: span 1;
  grid-row-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

.ux__color--relative {
  -ms-grid-row-align: stretch;
  -ms-grid-column: span 1;
  grid-column-end: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: span 6;
  grid-row-end: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  align-self: stretch;
}

#w-node-_6f41d085-98ee-9c8e-9334-3f04fce06489-2dd17d13,
#w-node-_6f41d085-98ee-9c8e-9334-3f04fce06486-2dd17d13,
#w-node-_546e9707-845e-080b-7f82-c0df8e4be664-2dd17d13 {
  -ms-grid-row-align: stretch;
  -ms-grid-column: span 1;
  grid-column-end: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: span 6;
  grid-row-end: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  align-self: stretch;
}
