/* Give the Spotify embed a bit of breathing room below. */
iframe[src*='open.spotify.com'] {
  margin-bottom: 4rem;
}

/* Add this class to YouTube iframes entered manually. */
.yt-embed-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.yt-embed-container iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
