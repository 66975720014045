.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 2rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-flex-line-pack: start;
}

@media screen and (max-width: 991px) {
  .form {
    display: block;
  }
}

.form--columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-top-style: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
}

.form__wrapper {
  max-width: 800px;

  /* Give wrapper a minimum height so that scroll-animations.js treats this as
   * a large element. Otherwise, it treats it as a small element (invisible
   * until fully intersecting the viewport) because the form hasn’t loaded by
   * the time scroll-animations.js processes the block. */
  min-height: 200px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  text-align: left;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column: 1;
  grid-column-end: 2;
  grid-column-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  grid-row-start: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column: 1;
  grid-column-end: 2;
  grid-column-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  grid-row-start: 1;
  clear: both;
  -ms-grid-column-span: 1;
  -ms-grid-row-span: 1;
}

.w-checkbox-input {
  margin-top: 4px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: -20px;
  float: left;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  background-color: #3898ec;
  background-image: url(https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

label {
  display: block;
  margin-bottom: 0px;
  padding-top: 1rem;
  color: #72686b;
  font-size: 0.8em;
  font-weight: 800;
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;
}

.label--white-text {
  color: hsla(0, 0%, 100%, 0.5);
}

.label--checkbox {
  margin-bottom: 0px;
  padding-top: 0rem;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.2;
  text-transform: none;
  cursor: pointer;
}

.input__label {
  position: relative;
  z-index: 1;
  margin-bottom: 0.25em;
  color: #72686b;
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .input__label {
    top: -1.3em;
    left: 0.875em;
    font-size: 0.6rem;
    letter-spacing: 0.07em;
    line-height: 1.1;
  }
}

/* TODO: need both? */
.input__label--asterisk {
  display: inline-flex;
}

.input__label--asterisk {
  display: block;
  position: absolute;
  top: -25%;
  width: 1em;
  height: 1em;
  padding-top: 1%;
  padding-left: 2.2%;
  -webkit-transform: translate(0px, 0.15em);
  -ms-transform: translate(0px, 0.15em);
  transform: translate(0px, 0.15em);
  border-radius: 50%;
  background-color: #ef243b;
  color: #fff;
  font-size: 120%;
  text-align: center;
}

.label--required {
  position: relative;
}

.label--required::before {
  content: "";
  position: absolute;
  right: calc(100% + 0.25em);
  color: #b2b2ab;

  /* Grey */
  font-family: Icons;
  font-weight: 400;
}

.input__label.input__label--focused {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  box-shadow: inset 0 0 0 1px #b2b2ab;
  color: rgba(35, 31, 32, 0.6);
}

.input__label.input__label--warning {
  box-shadow: inset 0 0 0 1px #ef243b;
  color: #ef243b;
}

.form__input {
  position: relative;
  flex-basis: calc(33.3333% - 4rem);
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  min-width: 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-flex-item-align: end;
}

@media screen and (max-width: 767px) {
  .form__input {
    min-width: 100%;
  }
}

.form__input.input__checkbox {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1.3;
  cursor: pointer;
}

.form__input.input--full {
  min-width: 100%;
}

.form__input.input--half {
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  min-width: 47.5%;
  -webkit-box-flex: 0;
}

.form__input.form__action {
  padding-top: 1rem;
}

.input__checkbox-box,
.input__select,
.input__textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.input__select,
.input__textarea {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.input__textarea {
  width: 100%;
  min-height: 7.5em;
  margin-bottom: 0px;
  padding: 1rem 0px;
  border-bottom: 1px solid #72686b;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.3;
}

.input__textarea:focus {
  border-bottom-style: dashed;
  border-bottom-color: #396eea;
}

.input__textarea::-webkit-input-placeholder,
.input__textarea:-ms-input-placeholder,
.input__textarea::-ms-input-placeholder,
.input__textarea::placeholder {
  color: #72686b;
}

.input__textarea.input__textarea--white-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
  color: #d3d3cc;
}

.input__textarea.input__textarea--white-text:focus {
  border-bottom-color: #396eea;
}

.input__textarea.input__textarea--white-text::-webkit-input-placeholder,
.input__textarea.input__textarea--white-text:-ms-input-placeholder,
.input__textarea.input__textarea--white-text::-ms-input-placeholder,
.input__textarea.input__textarea--white-text::placeholder {
  color: rgba(178, 178, 171, 0.75);
}

.input__checkbox-box::before {
  content: '';
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 320ms cubic-bezier(0.22, 1, 0.36, 1);
  opacity: 0;
  color: #f3f2ef;
  font-family: Icons;
}

.input__checkbox-box:checked {
  border-radius: 50%;
  border-color: #72686b;

  /* Grey - Dark */
  background-color: #72686b;

  /* Grey - Dark */
}

.input__checkbox-box:checked::before {
  transform: scale(1);
  opacity: 1;
}

.input__select {
  position: relative;
  min-height: 3rem;
  margin-bottom: 0px;
  padding: 1rem 2.25rem 1rem 0px;
  border-bottom: 1px solid #72686b;
  background-color: transparent;
  background-image: url("https://uploads-ssl.webflow.com/61f94f00557d8b46a3c4b8b8/6213f65f197731dbcb57223e_chevron-down.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 30px;
  font-size: 1em;
  line-height: 1;
  cursor: pointer;
}

.input__select:focus {
  border-bottom-style: dashed;
  border-bottom-color: #396eea;
}

.input__select.input__select--white-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
  color: #d3d3cc;
}

.input__select.input__select--white-text:focus {
  border-bottom-color: #396eea;
}

.checkbox__message {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  top: calc(100% - 1px);
  right: 0px;
  left: 0px;
  padding: 1rem;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  background-color: #231f20;
  color: #f3f2ef;
}

.checkbox__message[aria-hidden="false"] {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.form.newsletter-signup__form {
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  width: 61.803%;
  margin-top: 0rem;
  padding: 3rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 6rem;
  border-color: #231f20;
  background-color: #f3f2ef;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
}

.input__input {
  position: relative;
  width: 100%;
  max-width: 800px;
  min-height: 3rem;
  margin-bottom: 0px;
  padding: 1rem 0px;
  -webkit-transition: all 280ms ease-in-out;
  transition: all 280ms ease-in-out;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-radius: 0px;
  border-color: #000 #000 #72686b;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1;
  cursor: text;
}

.input__input:focus {
  border-bottom-style: dashed;
  border-bottom-color: #396eea;
}

.input__input::-webkit-input-placeholder,
.input__input:-ms-input-placeholder,
.input__input::-ms-input-placeholder,
.input__input::placeholder {
  color: #72686b;
}

.input__input.input__input--focus {
  box-shadow: inset 0 0 0 1px #231f20;
  color: #231f20;
}

.input__input.inout__input--warning {
  box-shadow: inset 0 0 0 1px #ef243b;
  color: #8e002e;
}

.input__input.input__select {
  padding-right: 3em;
  cursor: pointer;
}

.input__input.input__input--white-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
  color: #d3d3cc;
}

.input__input.input__input--white-text:focus {
  border-bottom-color: #396eea;
}

.input__input.input__input--white-text::-webkit-input-placeholder,
.input__input.input__input--white-text:-ms-input-placeholder,
.input__input.input__input--white-text::-ms-input-placeholder,
.input__input.input__input--white-text::placeholder {
  color: rgba(178, 178, 171, 0.75);
}

.form__input-warning {
  padding-top: 0.25em;
  color: #8e002e;
  font-size: 0.8em;
}

.input__input-warning-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 1.3em;
  right: 0.875em;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 1.4em;
  height: 1.4em;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  border-color: #ef243b;
  background-color: #fff;
  color: #ef243b;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 767px) {
  .input__input-warning-icon {
    top: 1em;
    right: 0.75em;
  }
}

.input__checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0px;
  float: none;
  border-bottom: 1px solid #72686b;
  color: #231f20;
  font-size: 1em;
  line-height: 1;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.input__checkbox.input__checkbox--white-text {
  border-bottom-color: hsla(0, 0%, 100%, 0.3);
  color: #d3d3cc;
}

.input__checkbox-box {
  position: relative;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0px;
  margin-right: 0.75em;
  margin-left: 0px;
  -webkit-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  border-width: 1px;
  border-style: solid;
  border-radius: 0em;
  border-color: #b2b2ab;
  font-family: Icons, sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-flex: 0;
}

.form__message {
  margin-top: 1.5rem;
}

@media screen and (max-width: 767px) {
  .form__message {
    margin-top: 1rem;
  }
}

.form__success-message {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.success-message__heading {
  margin-top: 0rem;
}

.icon.gradient-clip.background-gradient.success-message__icon {
  display: inline-block;
  font-size: 3rem;
  line-height: 1;
}

@media screen and (max-width: 479px) {
  .icon.gradient-clip.background-gradient.success-message__icon {
    font-size: 2rem;
  }
}

/* HubSpot Contact Form styles */

.hs-qualifier .hs-input {
  position: relative;
  min-height: 3.5rem;
  margin-bottom: 0px;
  padding: 1rem 2.25rem 1rem 0px;
  border-bottom: 1px solid #72686b;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
  background-image: url("https://uploads-ssl.webflow.com/61f94f00557d8b46a3c4b8b8/6213f65f197731dbcb57223e_chevron-down.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 30px;
  color: #d3d3cc;
  font-size: 1em;
  line-height: 1;
  cursor: pointer;
  appearance: none;
}

.form-variant-light .hs-qualifier .hs-input {
  margin-bottom: 10px;
  border-bottom: 1px solid #72686b;
  background-image: url("https://uploads-ssl.webflow.com/61f94f00557d8b46a3c4b8b8/6213f65f197731dbcb57223e_chevron-down.svg");
}

/* HubSpot forms refinements. */
.hs-fieldtype-radio.hs-form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 1rem;
}

/* Radio group field label. */
.hs-fieldtype-radio label {
  flex: 0 auto;
  padding: 0 1rem 0 0;
}

/* Radio item top-most wrapper. */
.hs-fieldtype-radio .input {
  flex: 1 auto;
}

/* Radio wrapper ul. */
.hs-fieldtype-radio .inputs-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

/* Radio list item. */
.hs-fieldtype-radio .hs-form-radio {
  margin: 0;
}

/* Radio list item pseudo-element. */
.hs-fieldtype-radio .hs-form-radio::before {
  content: none;
}

/* Radio label. */
.hs-fieldtype-radio .hs-form-radio-display {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  color: white;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: unset;
  text-transform: unset;
}

/* Radio input. */
.hs-fieldtype-radio .hs-input {
  width: 1.5rem;
  height: 1.5rem;
  min-height: unset;
  padding: 0;
  transition: var(--speed-fast) var(--transition-timing-function);
  border: 1px solid rgba(white, 0.3);
  background-image: url('/assets/images/checkmark.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0 0;
  cursor: pointer;
}

/* Radio input checked. */
.hs-fieldtype-radio .hs-input:checked {
  border: 1px solid rgba(white, 0.3);
  border-radius: 50%;
  background-color: rgba(#b2b2ab, 0.3);
  background-size: 80% auto;
}

/* Light variant of radio. */
.form-variant-light .hs-fieldtype-radio .hs-form-radio-display {
  color: black;
}

.form-variant-light .hs-fieldtype-radio .hs-input {
  border: 1px solid rgba(#72686b, 0.3);
  background-image: url('/assets/images/checkmark--dark.svg');
}

.form-variant-light .hs-fieldtype-radio .hs-input:checked {
  border: 1px solid rgba(#72686b, 0.3);
  background-color: none;
}
