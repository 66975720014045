.testimonial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .testimonial {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .testimonial {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.position-relative.testimonial__quote {
  -webkit-order: 3;
  order: 3;
  margin-top: 2rem;
  margin-bottom: 0em;
  margin-left: -0.04em;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
}

.position-relative.testimonial__quote {
  display: block;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.testimonial__quote {
  margin-bottom: 0rem;
  text-indent: -0.6ch;
}

@media screen and (max-width: 767px) {
  .testimonial__quote {
    line-height: 1.1;
  }
}

.position-relative.testimonial__quote {
  display: block;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.testimonial__quote.testimonial__quote--compact {
  display: inline;
  -webkit-order: 0;
  order: 0;
  margin-top: 0rem;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
}

@media screen and (max-width: 991px) {
  .testimonial__quote.testimonial__quote--compact {
    font-size: 1.75rem;
    line-height: 1.25;
  }
}

@media screen and (max-width: 767px) {
  .testimonial__quote.testimonial__quote--compact {
    font-size: 1.2rem;
    line-height: 1.4;
  }
}

.testimonial__quote.testimonial__quote--case-study {
  position: relative;
  z-index: 1;
}

.testimonial__heading {
  width: 100%;
  margin-top: 0em;
  color: rgba(243, 242, 239, 0.6);
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .testimonial__heading {
    font-size: 0.8rem;
    letter-spacing: 0.07em;
  }
}

.testimonial.testimonial--compact {
  display: block;
  clear: both;
}

.testimonial__byline {
  display: inline-block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-indent: 0ch;
}

@media screen and (max-width: 479px) {
  .testimonial__byline {
    font-size: 0.8rem;
  }
}

.testimonial__byline.a--dark {
  font-weight: 800;
}

.testimonial__image {
  position: relative;
  width: 80%;
  height: 80%;
  margin-top: 10%;
  margin-left: 10%;
  overflow: hidden;
  border-radius: 50%;
}

.shape-outside.shape-outside--circle .testimonial__image {
  clip-path: circle();
}

.shape-outside.shape-outside--circle.testimonial__shape {
  width: 30vw;
  height: 30vw;
  margin-top: -10%;
  margin-left: -10%;
}

@media screen and (max-width: 991px) {
  .shape-outside.shape-outside--circle.testimonial__shape {
    width: 38.197vw;
    height: 38.197vw;
    margin-top: -8%;
    margin-left: -8%;
  }
}

@media screen and (max-width: 479px) {
  .shape-outside.shape-outside--circle.testimonial__shape {
    width: 50vw;
    height: 50vw;
    margin-top: -10%;
    margin-left: -10%;
  }
}

.case-study-teaser__testimonial {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.3;
  text-indent: -0.6ch;
  -webkit-box-flex: 1;
}

@media screen and (max-width: 991px) {
  .case-study-teaser__testimonial {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 767px) {
  .case-study-teaser__testimonial {
    font-size: 1.1rem;
    text-align: left;
    text-indent: -0.6ch;
  }
}

@media screen and (max-width: 479px) {
  .case-study-teaser__testimonial {
    font-size: 1rem;
  }
}

.case-study-teaser__testimonial--unindented {
  text-indent: 0;
}
