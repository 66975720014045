.border--blue {
  border-width: 1px;
  border-style: solid;
  border-color: #396eea;
}

.border--yellow {
  border-width: 1px;
  border-style: solid;
  border-color: #c2c700;
}

.border--magenta {
  border-width: 1px;
  border-style: solid;
  border-color: #fc00a1;
}

.border--red {
  border-width: 1px;
  border-style: solid;
  border-color: #ef243b;
}

.border--orange {
  border-width: 1px;
  border-style: solid;
  border-color: #f05123;
}

.border--green {
  border-width: 1px;
  border-style: solid;
  border-color: #00b857;
}

.circle--bordered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 25%;
  left: -38.197%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  width: 61.803vw;
  height: 61.803vw;
  padding: 5% 5% 0.5% 0.5%;
  animation: spin 20s infinite linear;
  animation-duration: 80s;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  opacity: 0.3;
  -webkit-box-align: end;
  -ms-flex-align: end;
}

@media screen and (max-width: 991px) {
  .circle--bordered {
    width: 80vw;
    height: 80vw;
  }
}

@media screen and (max-width: 767px) {
  .circle--bordered {
    top: 50%;
  }
}

@media screen and (max-width: 479px) {
  .circle--bordered {
    top: 75%;
    left: 0%;
    width: 100vw;
    height: 100vw;
  }
}

.circle--bordered .circle--border-inner {
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  padding: 6% 6% 1.5% 1.5%;
  opacity: 1;
}

.circle--hang-right {
  right: -38.197%;
  left: auto;
}

@media screen and (max-width: 479px) {
  .circle--hang-right {
    right: 0%;
  }
}
