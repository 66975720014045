.block--curtains {
  display: block;
  width: 100vw;
  min-height: 0vh;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0vh;
  padding-bottom: 0vh;
  overflow: hidden;
}

.block--curtains__target {
  display: block;
  position: relative;
  left: 50%;
  min-width: 100vw;
  max-width: 100vw;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.block--curtains__image {
  display: block;
  width: 100%;
}

.block--curtains__image__picture {
  width: 100%;
}
