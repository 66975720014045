.service-icon--project-management g {
  transform-origin: 50% 50%;
}

.service-icon--project-management #child {
  animation-duration: 40s;
}

.service-icon--strategy path {
  transform-origin: 50% 50%;
}

.strategy-circle-0 {
  animation: strategy-circle-draw_0 12200ms ease-in-out 0ms infinite, strategy-circle-fade 12200ms linear 0ms infinite;
  stroke-dasharray: 2480 2482;
  stroke-dashoffset: 2481;
}

.strategy-circle-1 {
  animation: strategy-circle-draw_1 12200ms ease-in-out 0ms infinite, strategy-circle-fade 12200ms linear 0ms infinite;
  stroke-dasharray: 2108 2110;
  stroke-dashoffset: 2109;
}

.strategy-circle-2 {
  animation: strategy-circle-draw_2 12200ms ease-in-out 0ms infinite, strategy-circle-fade 12200ms linear 0ms infinite;
  stroke-dasharray: 1736 1738;
  stroke-dashoffset: 1737;
}

.strategy-circle-3 {
  animation: strategy-circle-draw_3 12200ms ease-in-out 0ms infinite, strategy-circle-fade 12200ms linear 0ms infinite;
  stroke-dasharray: 1364 1366;
  stroke-dashoffset: 1365;
}

.strategy-circle-4 {
  animation: strategy-circle-draw_4 12200ms ease-in-out 0ms infinite, strategy-circle-fade 12200ms linear 0ms infinite;
  stroke-dasharray: 993 995;
  stroke-dashoffset: 994;
}

.strategy-circle-5 {
  animation: strategy-circle-draw_5 12200ms ease-in-out 0ms infinite, strategy-circle-fade 12200ms linear 0ms infinite;
  stroke-dasharray: 621 623;
  stroke-dashoffset: 622;
}

.strategy-circle-6 {
  animation: strategy-circle-draw_6 12200ms ease-in-out 0ms infinite, strategy-circle-fade 12200ms linear 0ms infinite;
  stroke-dasharray: 249 251;
  stroke-dashoffset: 250;
}

@keyframes strategy-circle-draw {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strategy-circle-fade {
  0% {
    stroke-opacity: 1;
  }

  96.72131147540983% {
    stroke-opacity: 1;
  }

  100% {
    stroke-opacity: 0;
  }
}

@keyframes strategy-circle-draw_0 {
  6.557377049180328% {
    stroke-dashoffset: 2481;
  }

  23.578566062589402% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strategy-circle-draw_1 {
  23.578566062589402% {
    stroke-dashoffset: 2109;
  }

  38.04760581643048% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strategy-circle-draw_2 {
  38.0476058164305% {
    stroke-dashoffset: 1737;
  }

  49.964496310703595% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strategy-circle-draw_3 {
  49.964496310703595% {
    stroke-dashoffset: 1365;
  }

  59.32923754540871% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strategy-circle-draw_4 {
  59.329237545408695% {
    stroke-dashoffset: 994;
  }

  66.14869013683499% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strategy-circle-draw_5 {
  66.14869013683499% {
    stroke-dashoffset: 622;
  }

  70.4159934686933% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes strategy-circle-draw_6 {
  70.4159934686933% {
    stroke-dashoffset: 250;
  }

  72.1311475409836% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.service-icon--design #sun {
  animation: duskTillDawn 16s infinite alternate linear;
}

@keyframes duskTillDawn {
  from {
    transform: translate(-10%, -5%);
  }

  to {
    transform: translate(0%, 0%);
  }
}

.service-icon--design #mountain {
  animation: mountainDew 16s infinite alternate linear;
}

@keyframes mountainDew {
  from {
    transform: translateX(-10%);
  }

  to {
    transform: translateX(5%);
  }
}

.service-icon--design #clouds {
  animation: rollingClouds 32s infinite alternate linear;
}

@keyframes rollingClouds {
  from {
    transform: translateX(50%);
  }

  to {
    transform: translateX(0%);
  }
}

.service-icon--development #circleBottom,
.service-icon--development #half,
.service-icon--development #full {
  animation: circleDrop 5s infinite alternate cubic-bezier(0.22, 1, 0.36, 1);
}

.service-icon--development #circleBottom {
  animation-name: circleDrop;
}

@keyframes circleDrop {
  0% {
    transform: translateY(-29%);
    opacity: 0;
  }

  5% {
    transform: translateY(-29%);
    opacity: 0;
  }

  15% {
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.service-icon--development #half {
  animation-name: halfSquareDraw;
  stroke-dasharray: 1900px;
}

@keyframes halfSquareDraw {
  0% {
    stroke-dashoffset: 1900px;
  }

  15% {
    stroke-dashoffset: 1900px;
  }

  25% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.service-icon--development #full {
  animation-name: fullSquareGrow;
}

@keyframes fullSquareGrow {
  0% {
    height: 0;
  }

  25% {
    height: 0;
  }

  35% {
    height: 783.539px;
  }

  100% {
    height: 783.539px;
  }
}

/* Set Animations */
.service-icon--devops #line1,
.service-icon--devops #line2,
.service-icon--devops #line3,
.service-icon--devops #line4,
.service-icon--devops #line5,
.service-icon--devops #line6,
.service-icon--devops #line7,
.service-icon--devops #line8 {
  animation: 6s infinite alternate cubic-bezier(0.22, 1, 0.36, 1);
  stroke-dasharray: 245px;
}

.service-icon--devops #node1,
.service-icon--devops #node2,
.service-icon--devops #node3,
.service-icon--devops #node4,
.service-icon--devops #node5,
.service-icon--devops #node6,
.service-icon--devops #node7,
.service-icon--devops #node8 {
  animation: 6s infinite alternate cubic-bezier(0.22, 1, 0.36, 1);
}

/* Nodes */
.service-icon--devops #nodes circle {
  transform-origin: center center;
}

.service-icon--devops #node1 {
  animation-name: nodeGrow1;
}

@keyframes nodeGrow1 {
  0% {
    transform: scale(0);
  }

  3.125% {
    transform: scale(0);
  }

  6.25% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.service-icon--devops #node2 {
  animation-name: nodeGrow2;
}

@keyframes nodeGrow2 {
  0% {
    transform: scale(0);
  }

  6.25% {
    transform: scale(0);
  }

  9.375% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.service-icon--devops #node3 {
  animation-name: nodeGrow3;
}

@keyframes nodeGrow3 {
  0% {
    transform: scale(0);
  }

  9.375% {
    transform: scale(0);
  }

  12.5% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.service-icon--devops #node4 {
  animation-name: nodeGrow4;
}

@keyframes nodeGrow4 {
  0% {
    transform: scale(0);
  }

  12.5% {
    transform: scale(0);
  }

  15.625% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.service-icon--devops #node5 {
  animation-name: nodeGrow5;
}

@keyframes nodeGrow5 {
  0% {
    transform: scale(0);
  }

  15.625% {
    transform: scale(0);
  }

  18.75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.service-icon--devops #node6 {
  animation-name: nodeGrow6;
}

@keyframes nodeGrow6 {
  0% {
    transform: scale(0);
  }

  18.75% {
    transform: scale(0);
  }

  21.875% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.service-icon--devops #node7 {
  animation-name: nodeGrow7;
}

@keyframes nodeGrow7 {
  0% {
    transform: scale(0);
  }

  21.875% {
    transform: scale(0);
  }

  25% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.service-icon--devops #node8 {
  animation-name: nodeGrow8;
}

@keyframes nodeGrow8 {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(0);
  }

  28.125% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

/* Lines */
.service-icon--devops #line1 {
  animation-name: lineGrow1;
}

@keyframes lineGrow1 {
  0% {
    stroke-dashoffset: 245px;
  }

  25% {
    stroke-dashoffset: 245px;
  }

  28.125% {
    stroke-dashoffset: 490px;
  }

  100% {
    stroke-dashoffset: 490px;
  }
}

.service-icon--devops #line2 {
  animation-name: lineGrow2;
}

@keyframes lineGrow2 {
  0% {
    stroke-dashoffset: 245px;
  }

  28.125% {
    stroke-dashoffset: 245px;
  }

  31.25% {
    stroke-dashoffset: 490px;
  }

  100% {
    stroke-dashoffset: 490px;
  }
}

.service-icon--devops #line3 {
  animation-name: lineGrow3;
}

@keyframes lineGrow3 {
  0% {
    stroke-dashoffset: 245px;
  }

  31.25% {
    stroke-dashoffset: 245px;
  }

  34.375% {
    stroke-dashoffset: 490px;
  }

  100% {
    stroke-dashoffset: 490px;
  }
}

.service-icon--devops #line4 {
  animation-name: lineGrow4;
}

@keyframes lineGrow4 {
  0% {
    stroke-dashoffset: 245px;
  }

  34.375% {
    stroke-dashoffset: 245px;
  }

  37.5% {
    stroke-dashoffset: 490px;
  }

  100% {
    stroke-dashoffset: 490px;
  }
}

.service-icon--devops #line5 {
  animation-name: lineGrow5;
}

@keyframes lineGrow5 {
  0% {
    stroke-dashoffset: 245px;
  }

  37.5% {
    stroke-dashoffset: 245px;
  }

  40.625% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.service-icon--devops #line6 {
  animation-name: lineGrow6;
}

@keyframes lineGrow6 {
  0% {
    stroke-dashoffset: 245px;
  }

  40.625% {
    stroke-dashoffset: 245px;
  }

  43.75% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.service-icon--devops #line7 {
  animation-name: lineGrow7;
}

@keyframes lineGrow7 {
  0% {
    stroke-dashoffset: 245px;
  }

  43.75% {
    stroke-dashoffset: 245px;
  }

  46.875% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.service-icon--devops #line8 {
  animation-name: lineGrow8;
}

@keyframes lineGrow8 {
  0% {
    stroke-dashoffset: 245px;
  }

  46.875% {
    stroke-dashoffset: 245px;
  }

  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.service-icon--migration circle {
  animation: 6s infinite alternate cubic-bezier(0.22, 1, 0.36, 1);
}

.service-icon--migration #level2 {
  --target-position: -8.5%;

  animation-name: moveUp2;
}

.service-icon--migration #level3 {
  --target-position: -17%;

  animation-name: moveUp3;
}

.service-icon--migration #level4 {
  --target-position: -25.5%;

  animation-name: moveUp4;
}

.service-icon--migration #level5 {
  --target-position: -33.5%;

  animation-name: moveUp5;
}

.service-icon--migration #level6 {
  --target-position: -42%;

  animation-name: moveUp6;
}

.service-icon--migration #level7 {
  --target-position: -50.5%;

  animation-name: moveUp7;
}

/**
 * Each migration circle gets its own keyframe set because the second
 * percentage changes on each. Sadly, CSS variables cannot be interpolated to
 * define a keyframe percentage.
 */
@keyframes moveUp2 {
  0% {
    transform: translateY(0%);
  }

  12.5% {
    transform: translateY(var(--target-position));
  }

  87.5% {
    transform: translateY(var(--target-position));
  }

  100% {
    transform: translateY(var(--target-position));
  }
}

@keyframes moveUp3 {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(var(--target-position));
  }

  87.5% {
    transform: translateY(var(--target-position));
  }

  100% {
    transform: translateY(var(--target-position));
  }
}

@keyframes moveUp4 {
  0% {
    transform: translateY(0%);
  }

  37.5% {
    transform: translateY(var(--target-position));
  }

  87.5% {
    transform: translateY(var(--target-position));
  }

  100% {
    transform: translateY(var(--target-position));
  }
}

@keyframes moveUp5 {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(var(--target-position));
  }

  87.5% {
    transform: translateY(var(--target-position));
  }

  100% {
    transform: translateY(var(--target-position));
  }
}

@keyframes moveUp6 {
  0% {
    transform: translateY(0%);
  }

  62.5% {
    transform: translateY(var(--target-position));
  }

  87.5% {
    transform: translateY(var(--target-position));
  }

  100% {
    transform: translateY(var(--target-position));
  }
}

@keyframes moveUp7 {
  0% {
    transform: translateY(0%);
  }

  75% {
    transform: translateY(var(--target-position));
  }

  87.5% {
    transform: translateY(var(--target-position));
  }

  100% {
    transform: translateY(var(--target-position));
  }
}

.service-icon--diagnostics #objects {
  transform-origin: center;
  animation: microscope 6s infinite alternate ease-in-out;
}

@keyframes microscope {
  0% {
    transform: scale(1.15) rotateZ(5deg);
  }

  80% {
    transform: scale(1) rotateZ(0deg);
  }

  100% {
    transform: scale(1) rotateZ(0deg);
  }
}

.service-icon--support path {
  transform-origin: center;
  animation: 6s infinite cubic-bezier(0.22, 1, 0.36, 1);
  opacity: 1;
}

.service-icon--support #cycle1 {
  animation-name: cycleFade1;
}

@keyframes cycleFade1 {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.service-icon--support #cycle2 {
  animation-name: cycleFade2;
}

@keyframes cycleFade2 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.service-icon--support #cycle3 {
  animation-name: cycleFade3;
}

@keyframes cycleFade3 {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.service-icon--support #cycle4 {
  animation-name: cycleFade4;
}

@keyframes cycleFade4 {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.service-icon--support #cycle5 {
  animation-name: cycleFade5;
}

@keyframes cycleFade5 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.service-icon--support #cycle6 {
  animation-name: cycleFade6;
}

@keyframes cycleFade6 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/**
 * Remove service icon motion when reduced motion is required. See
 * `assets/scripts/utility.js` for details of how this class is added.
 *
 * TODO: We are forced to duplicate this code to target both the media query
 * and the `?reduce-motion` query string. When moving this to SCSS, we should
 * create a mixin to use in both scenarios.
 */
.reduce-motion .service-icon,
.reduce-motion .service-icon * {
  animation: none !important;
  animation-name: none !important;
}

.reduce-motion .service-icon--migration circle {
  transform: translateY(var(--target-position));
}

.reduce-motion .service-icon--strategy path {
  stroke-dashoffset: 0;
  stroke-opacity: 1;
}

@media screen and (prefers-reduced-motion: reduce) {
  .service-icon,
  .service-icon * {
    animation: none !important;
    animation-name: none !important;
  }

  .service-icon--migration circle {
    transform: translateY(var(--target-position));
  }

  .reduce-motion .service-icon--strategy path {
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
}
