.gallery {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.gallery__image {
  position: relative;
  z-index: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 38.197%;
  overflow: hidden;
  -webkit-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 1rem;
  -webkit-box-flex: 0;
}

@media screen and (max-width: 767px) {
  .gallery__image {
    border-radius: 0.5rem;
  }
}

.gallery__image:hover {
  z-index: 3;
}

.gallery__image--highlighted {
  z-index: 2;
  width: 50%;
  margin-right: -13.197%;
  margin-left: -13.197%;
}

.gallery .gallery__image,
.gallery .gallery__image.gallery__image--highlighted {
  width: 100%;
  margin: 0;
}

.gallery__image-img {
  display: block;
  width: 100%;
}

.gallery picture:nth-child(2) {
  width: 50%;
  margin-right: -13.197%;
  margin-left: -13.197%;
}
