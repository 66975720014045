.stats__value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 1.3em;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  opacity: 0.7;
  font-family: Inter, sans-serif;
  font-size: 6.5vw;
  font-weight: 800;
  letter-spacing: -0.04em;
  line-height: 1;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .stats__value {
    height: 1.5em;
    font-size: 12vw;
  }
}

@media screen and (max-width: 479px) {
  .stats__value {
    height: 1.3em;
    font-size: 15vw;
  }
}

.stats__value.stats__value--alternative {
  /**
   * The value below equalizes the height of the --alternative modifier to that
   * of the regular (read: no modifier) .stats__value element.
   *
   * i.e.
   * 2.1125em @ font-size: 4vw === 1.3em @ font-size: 6.5vw.
   *
   * This allows us to keep the height relative to the size of the type whilst
   * keeping the size of the type relative to the width of the viewport.
   */
  height: 2.1125em;
  font-size: 4vw;
  letter-spacing: -0.03em;
}

@media screen and (max-width: 991px) {
  .stats__value.stats__value--alternative {
    /**
     * The same principle applies here, but with slightly different ratios.
     *
     * i.e.
     * 2.25em @ font-size: 8vw === 1.5em @ font-size: 12vw.
     */
    height: 2.25em;
    font-size: 8vw;
  }
}

@media screen and (max-width: 479px) {
  .stats__value.stats__value--alternative {
    /**
     * At this viewport width, these elements stack in a single column, so
     * there is no need to equalize the height.
     */
    height: 1em;
    font-size: 10vw;
    letter-spacing: -0.02em;
  }
}
