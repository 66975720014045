/* Filter styling */
.case-study__filter {
  display: flex;
}

.case-study__filter li {
  margin-right: 0.25em;
  margin-left: 0.25em;
  font-size: 0.9em;
}

.case-study__filter .button:hover,
.case-study__filter .button:focus-visible {
  z-index: 1;
}

.case-study__filter .button::after:hover,
.case-study__filter .button::after:focus-visible {
  z-index: -1;
}

/* If there is no js, might as well hide filter. */
.no-js .case-study__filter {
  display: none;
}

.halves--case-studies:nth-of-type(even) {
  background-color: #ededea;
}

@media screen and (max-width: 991px) {
  .halves--case-studies .halves__column:first-of-type {
    padding-bottom: 1rem;
  }
}

/* Remove unordered list custom bullets. */
.case-study__services-list {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  row-gap: 0.4em;
  flex-wrap: wrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  max-width: 100%;
  margin-bottom: 1.5rem;
  padding: 0;
  color: #b2b2ab;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
}

@media screen and (max-width: 991px) {
  .case-study__services-list {
    font-size: 0.65rem;
  }
}

.case-study__services-list-item {
  display: inline-block;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: 0em;
}

.case-study__services-list-item:first-of-type {
  margin-left: 0;
}

.case-study__services-list-item::before {
  content: none;
}

.case-study__services-list-item::after {
  content: '•';
  position: absolute;
  top: 0;
  right: 0.5em;
}

.case-study__services-list-item:last-of-type::after {
  display: none;
}

.case-study__services-list-item-term {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0.75em;
  margin-left: 0;
  padding-right: 0.75em;
  background-image: none;
  color: #72686b;
  font-weight: 400;
  letter-spacing: 0.03em;
  white-space: nowrap;
  -webkit-box-align: center;
}

#case-studies .halves__column.halves__column--wide.media--reverse,
#case-studies .halves__column.halves__column--wide.media {
  max-height: 625px;
  overflow: hidden;
}
