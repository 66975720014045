.listing {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #f3f2ef;
  color: #231f20;
}

@media screen and (max-width: 767px) {
  .listing {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

#case-studies-container.listing {
  padding-bottom: 0;
}

.full-width {
  padding: 0 10%;
}

.halves {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  padding: 6rem 10%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
}

@media screen and (max-width: 991px) {
  .halves {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    padding: 4rem 6%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
  }
}

@media screen and (max-width: 767px) {
  .halves {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 479px) {
  .halves {
    padding: 2rem 7.5%;
  }
}

@media screen and (min-width: 767px) {
  .halves--center {
    -webkit-align-items: center;
    align-items: center;
  }
}

.halves.halves--nude {
  padding: 0rem 0%;
}

.halves__column {
  width: 45%;
}

@media screen and (max-width: 991px) {
  .halves__column {
    width: 100%;
  }
}

.halves__column.halves__column--whole {
  width: 100%;
}

.halves__column.halves__column--wide {
  -webkit-align-items: center;
  align-items: center;
  width: 55.6227%;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .halves__column.halves__column--wide {
    width: 100%;
  }
}

.halves__column.halves__column--narrow {
  width: 34.3773%;
}

@media screen and (max-width: 991px) {
  .halves__column.halves__column--narrow {
    width: 100%;
  }
}

.halves__column--narrow .halves__title {
  margin-bottom: 1rem;
}

.halves__column--narrow .halves__subtitle {
  margin-top: 0;
}

.halves__column.halves__column--wide.media--reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.halves.halves--reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@media screen and (max-width: 991px) {
  .halves.halves--reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.halves--sm-reversed {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media screen and (max-width: 991px) {
  .halves--sm-reversed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.halves--sm-reversed .media--reverse {
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .halves--sm-reversed .media--reverse {
    align-self: center;
    margin-bottom: 2rem;
  }
}

.halves--reverse .media--reverse {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .halves--reverse .media--reverse {
    margin-bottom: 2em;
  }
}

.halves.halves--reverse.halves--services {
  padding-right: 10%;
  padding-left: 8%;
}

@media screen and (max-width: 991px) {
  .halves.halves--reverse.halves--services {
    padding-right: 6%;
    padding-left: 6%;
  }
}

@media screen and (max-width: 479px) {
  .halves.halves--reverse.halves--services {
    padding-right: 7.5%;
    padding-left: 7.5%;
  }
}

.media--feature {
  position: relative;
}

@media screen and (max-width: 991px) {
  .media--feature {
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .media--feature {
    max-width: 80%;
  }
}

.media--feature .media--feature-badge {
  position: absolute;
  z-index: 1;
  top: -30%;
  right: -10%;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .media--feature .media--feature-badge {
    top: -47%;
    right: -17%;
  }
}

@media screen and (max-width: 991px) {
  .media--feature .media--feature-badge {
    top: -20%;
    right: -7%;
  }
}

@media screen and (max-width: 585px) {
  .media--feature .media--feature-badge {
    top: -25%;
    right: -7%;
  }
}

@media screen and (min-width: 1799px) {
  .media--feature .media--feature-badge {
    top: -30%;
    right: -12%;
  }
}

.media--feature .media--feature-badge h5 {
  position: absolute;
  z-index: 1;
  top: calc(50% - 20px);
  left: calc(50% - 25px);
  width: 50px;
  margin: 0;
  color: #fff;
  text-align: center;
}

.media--feature-icon {
  font-family: Icons, sans-serif;
  font-size: 110px;
}

@media screen and (max-width: 992px) {
  .media--feature-icon {
    font-size: 125px;
  }
}

@media screen and (max-width: 585px) {
  .media--feature-icon {
    font-size: 85px;
  }
}

@media screen and (min-width: 1799px) {
  .media--feature-icon {
    font-size: 125px;
  }
}
