#clients .block__client-list {
  display: flex;
  grid-column-gap: 3rem;
  grid-row-gap: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.body--landing .block--client-highlight h2 {
  margin-top: 0;
}

@media screen and (min-width: 767px) {
  #clients .block__client-list {
    grid-column-gap: 90px;
    grid-row-gap: 20px;
  }

  #clients .block__client-list.block__client-list--small {
    grid-column-gap: 3rem;
  }
}

#clients .w-flex-block {
  display: flex;
  top: -25px;
  flex: 0 0 45%;
  height: 100%;
  min-height: 200px;
  margin: 2px 0;
}

#clients .block__client-list--small .client-logo-block {
  flex: 0 0 25%;
  height: 100px;
  min-height: unset;
}

/* todo: Client logo styles need to be reconsidered. This is styling specific to `client-highlight.webc`. */
#clients.client-logos--short .block__client-list--small .client-logo-block {
  height: 50px;
}

@media screen and (max-width: 767px) {
  .block--client-highlight .container {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 767px) {
  #clients .w-flex-block {
    top: auto;
    flex: 0 0 26%;
    min-height: 200px;
  }

  #clients .block__client-list--small .client-logo-block {
    height: 125px;
  }
}

@media screen and (min-width: 991px) {
  #clients .w-flex-block {
    flex: 0 0 15%;
    min-height: 225px;
    margin: 2px 0;
  }

  #clients .block__client-list--small .client-logo-block {
    flex: 0 0 13%;
    height: 150px;
  }

  #clients.block--client-highlight-large .w-flex-block {
    flex: 0 0 20%;
  }
}

@media screen and (min-width: 767px) {
  /* todo: Client logo styles need to be reconsidered. This is styling specific to `client-highlight.webc`. */
  #clients.client-logos--short .block__client-list--small .client-logo-block {
    height: 100px;
  }
}

@media screen and (min-width: 1220px) {
  #clients .client-logo-block {
    min-height: 175px;
  }

  #clients .block__client-list--small .client-logo-block {
    height: 175px;
  }
}

.client-logo-block {
  position: relative;
}

.clients-list__card {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.clients-list__card .clients-list__card-details {
  transform: scale(0.5) translate(0, 87px);
  transition: transform cubic-bezier(0.22, 1, 0.36, 1) 320ms, opacity cubic-bezier(0.22, 1, 0.36, 1) 320ms;
  opacity: 0;
}

@media screen and (min-width: 767px) {
  .clients-list__card .clients-list__card-details {
    transform: scale(0.5) translate(5px, 80px);
  }
}

@media screen and (min-width: 991px) {
  .clients-list__card .clients-list__card-details {
    transform: scale(0.5) translate(40px, 0);
  }
}

.client-logo-block:focus .clients-list__card-details,
.client-logo-block:focus-within .clients-list__card-details,
.client-logo-block:hover .clients-list__card-details {
  transform: scale(1);
  opacity: 1;
}

.clients-list__card-details {
  display: flex;
  position: absolute;
  top: 0;
  left: -10px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% + 20px);
  height: 100%;
  background: #2d65e7;
  color: #fff;
  font-size: 0.75em;
  font-weight: 400;
  cursor: pointer;
}

@media screen and (min-width: 767px) {
  .clients-list__card-details,
  .clients-list__card-details {
    left: -20px;
    width: calc(100% + 40px);
  }
}

.clients-list__image {
  width: 100%;
  object-fit: contain;
}

.clients-list__card-details .a--dark {
  background-image: linear-gradient(to bottom, rgba(237, 237, 234, 0.4), rgba(237, 237, 234, 0.4));
  color: #fff;
}

.clients-list__card-details-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 17px;
}

.clients-list__card-details h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 800;
}

.clients-list__card-details p {
  flex-grow: 1;
  margin-bottom: 0;
  font-size: 0.9em;
}

.clients-list__card-details .clients-list__card-details-anchor span {
  font-weight: 700;
}

.block--benz-clients-highlight .block__client-list--small .clients-list__image {
  height: 35px;
}

#clients.block--benz-clients-highlight .block__client-list--small .client-logo-block {
  display: flex;
  align-items: center;
  height: 125px;
}

@media screen and (min-width: 767px) {
  #clients.client-logos--encapsulated .block__client-list.block__client-list--small {
    grid-column-gap: 5rem;
  }
}

@media screen and (min-width: 991px) {
  .client-logos--encapsulated .container {
    width: 50%;
  }

  .client-logos--encapsulated .block__client-list {
    padding: 2rem 1rem;
    background-color: #8e002e;
  }

  /* This is mostly an example to show what we can do for landing pages where we are using a limited number of logos, so we'd like them to stand out more. Essentially we are using CSS to count the number of siblings and apply different widths. */

  /* https://stackoverflow.com/questions/8720931/can-css-detect-the-number-of-children-an-element-has */
  #clients .block__client-list .client-logo-block:first-child:nth-last-child(2),
  #clients .block__client-list .client-logo-block:first-child:nth-last-child(2) ~ div {
    flex: 0 0 35%;
  }
}
