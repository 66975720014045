#related-content .button__wrapper {
  justify-content: flex-start;
  -webkit-box-pack: start;
  margin-top: 2rem;
}

.related-content__terms {
  position: absolute;
  top: 0px;
  left: 4.5vw;
  max-height: 100%;
  overflow: hidden;
  color: rgba(243, 242, 239, 0.2);
  font-size: 0.7rem;
  letter-spacing: 0.15em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: default;
}

@media screen and (max-width: 991px) {
  .related-content__terms {
    top: 2.75rem;
    left: 0vw;
    max-width: 100%;
    max-height: none;
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 767px) {
  .related-content__terms {
    top: 1.75rem;
  }
}

/* Set terms as vertical for desktop+ only. */
@media (min-width: 769px) {
  .related-content__terms {
    writing-mode: vertical-lr;
  }
}

.related-content__terms a {
  color: rgba(243, 242, 239, 0.2);

  /* White */
  font-weight: 400;
}

.related-content__terms a:hover {
  color: rgba(243, 242, 239, 0.4);

  /* White */
}
