.footer {
  z-index: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #231f20;
  color: #b2b2ab;
}

.footer__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0em;
  padding-right: 0vw;
  padding-left: 0vw;
  font-size: 1.2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.footer__menu-item {
  margin-right: 0.5em;
  margin-bottom: 0em;
}

.footer__menu-item.footer__menu-item--copyright {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 0em;
  font-size: 0.8rem;
  line-height: 1.3;
  text-align: right;
  -webkit-box-flex: 1;
  mix-blend-mode: plus-lighter;
}

.footer__menu-item-link {
  display: block;
  margin-right: 0em;
  margin-left: 0em;
  background-image: none;
  color: rgba(243, 242, 239, 0.4);
  font-family: Icons, sans-serif;
  font-weight: 400;
}

.footer__menu-item-link:hover {
  color: #f3f2ef;
}

.footer__menu-item--copyright-span {
  margin-left: 1em;
}

@media screen and (max-width: 991px) {
  .footer__menu {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
  }

  .footer__menu-item.footer__menu-item--copyright {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    -webkit-box-flex: 0;
  }

  .footer__menu-item--copyright-span {
    display: block;
    margin-top: 1em;
    margin-left: 0em;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding-bottom: 4rem;
  }

  .footer__menu {
    font-size: 1.3333rem;
  }

  .footer__menu-item-link {
    margin-bottom: 0.5em;
  }

  .footer__menu-item {
    margin-left: 0.5em;
  }

  .footer__menu-item.footer__menu-item--copyright {
    line-height: 1.5;
  }
}
