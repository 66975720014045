.pager {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-top: 4rem;
  margin-bottom: 0rem;
  padding-right: 0rem;
  padding-left: 0rem;
  font-size: 1rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
}

.pager__item {
  margin-bottom: 0rem;
  margin-left: 0.3rem;
  text-align: center;
}

.pager__item::before {
  content: none;
}

.pager__item-link {
  display: block;
  margin-right: 0em;
  margin-left: 0em;
  padding: 0.5rem 0.7rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 2rem;
  border-color: rgba(178, 178, 171, 0);
  background-image: none;
  color: #72686b;
  font-weight: 400;
  line-height: 1;
}

.pager__item-link:hover {
  background-color: #2d65e7;
  color: #f3f2ef;
}

.pager__item-link.w--current {
  border-color: #b2b2ab;
  color: #b2b2ab;
}

.pager__item-link.w--current:hover {
  background-color: transparent;
}

.pager__item-link.pager__item-link-actions {
  font-family: Icons, sans-serif;
}

.pager__item-link.pager__item-link--inactive {
  color: #b2b2ab;
}

.pager__item-link.pager__item-link--inactive:hover {
  background-color: transparent;
}

.pager__item-link.pager__item-link-ellipsis {
  padding-right: 0rem;
  padding-left: 0rem;
  color: #b2b2ab;
}

.pager__item-link.pager__item-link-ellipsis:hover {
  background-color: transparent;
  color: #b2b2ab;
}

@media screen and (max-width: 767px) {
  .pager {
    font-size: 0.8rem;
  }
}
