.headshots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  padding-right: 2px;
  padding-left: 2px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

@media screen and (max-width: 991px) {
  .headshots {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .headshots {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 479px) {
  .headshots {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.headshots__person {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  margin-right: 0em;
  margin-bottom: 2rem;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  color: #d3d3cc;
  -webkit-box-flex: 0;
}

@media screen and (max-width: 991px) {
  .headshots__person {
    -webkit-flex-basis: 33.3333%;
    -ms-flex-preferred-size: 33.3333%;
    flex-basis: 33.3333%;
  }
}

@media screen and (max-width: 767px) {
  .headshots__person {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media screen and (max-width: 479px) {
  .headshots__person {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.headshots__person:hover {
  color: #f3f2ef;
}

.headshots__person-image {
  display: block;
  width: 100%;
  -webkit-filter: contrast(130%) grayscale(100%);
  filter: contrast(130%) grayscale(100%);
  -webkit-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  mix-blend-mode: screen;
}

.headshots__person-title {
  margin-top: 0.5em;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.3;
}

.headshots__person-text {
  padding: 1em;
  line-height: 1.1;
  text-align: center;
}

.headshots__person-image-wrapper {
  position: relative;
  overflow: hidden;
}

.headshots__person-image-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 320ms cubic-bezier(0.22, 1, 0.36, 1);

  /* Tertiary Gradient */
  opacity: 0.7;

  /* Primary Gradient */
  background-image: linear-gradient(450deg, #ef243b, rgba(96, 97, 151, 0) 70%), linear-gradient(135deg, #396eea 30%, rgba(57, 110, 234, 0) 80%), linear-gradient(225deg, #c2c700 5%, rgba(194, 199, 0, 0) 80%), linear-gradient(0deg, #fc00a1, rgba(252, 0, 161, 0) 71%);
  mix-blend-mode: screen;
}

.headshots__person-image-wrapper--secondary::after {
  /* Secondary Gradient */
  background-image: radial-gradient(circle farthest-corner at 120% -20%, #396eea 10%, rgba(57, 110, 234, 0) 50%), radial-gradient(circle farthest-corner at 40% -30%, #fc00a1, rgba(252, 0, 161, 0) 54%), radial-gradient(circle farthest-corner at 60% 20%, #00426a 16%, rgba(0, 66, 106, 0)), radial-gradient(circle farthest-corner at 0% 60%, #396eea, rgba(57, 110, 234, 0)), linear-gradient(328deg, #fc00a1 30%, rgba(252, 0, 161, 0));
}

.headshots__person-image-wrapper--tertiary::after {
  /* Tertiary Gradient */
  background-image: radial-gradient(circle farthest-corner at 120% -20%, #396eea 10%, rgba(57, 110, 234, 0) 50%), radial-gradient(circle farthest-corner at 40% -30%, #fc00a1, rgba(252, 0, 161, 0) 54%), radial-gradient(circle farthest-corner at 60% 20%, #00426a 16%, rgba(0, 66, 106, 0)), radial-gradient(circle farthest-corner at 0% 60%, #396eea, rgba(57, 110, 234, 0)), linear-gradient(328deg, #fc00a1 30%, rgba(252, 0, 161, 0));
}

.headshots__person:hover .headshots__person-image-wrapper::after {
  opacity: 0;
}

.headshots__person:hover .headshots__person-image {
  transform: scale(1.1);
  filter: saturate(100%) contrast(100%);
}
